<template>
	<div>
		<a-modal v-model:visible="visible" width="700px">
			<template #title>
				<modalHeader title="资产转移"></modalHeader>
			</template>
			<template #footer>
				<a-button @click="close">取消</a-button>
				<a-button @click="onSubmit" type="primary">转移</a-button>
			</template>
			<div>
				<a-form ref="formRef" :model="modelRef" :label-col="{ span: 4 }" :wrapper-col="{ span: 16 }">
					<a-form-item label="原使用人" :rules="[{ required: true, message: '必填项不允许为空' }]">
						<!-- <userSelectModal v-model:value="modelRef.oldUserId" :isAll="true"></userSelectModal> -->
						<a-select show-search
							v-model:value="modelRef.oldUserId"
							placeholder="请输入使用人姓名或手机号"
							:default-active-first-option="false"
							:show-arrow="false"
							:filter-option="false"
							:not-found-content="null"
							:options="oldUserData"
							:field-names="{value:'id',label:'username',options: 'oldUserData'}"
							@search="oldHandleSearch"
							@change="handleChange"
						></a-select>
					</a-form-item>
					<a-form-item label="新使用人" :rules="[{ required: true, message: '必填项不允许为空' }]">
						<a-select
							v-model:value="modelRef.nowUserId"
							show-search
							placeholder="请输入使用人姓名或手机号"
							:default-active-first-option="false"
							:show-arrow="false"
							:filter-option="false"
							:not-found-content="null"
							:options="newUserData"
							:field-names="{value:'id',label:'username',options: 'newUserData'}"
							@search="newHandleSearch"
							@change="handleChange"
						></a-select>
					</a-form-item>
					
					<a-form-item>
						<div style="padding-left: 40px;">
							<a-checkbox v-model:checked="checked">两人员名下资产互转</a-checkbox>
						</div>
					</a-form-item>
				</a-form>
				<a-alert type="warning" message="说明：资产转移即将原使用人名下资产批量转移至新使用人。如勾选“两人名下资产互转”选项，则两人的资产将实现批量互转。"></a-alert>
			</div>
		</a-modal>
	</div>
</template>

<script>
	import { updateAssetsUser } from '@/service/modules/property.js';
	import { getAllUserList } from '@/service/modules/global.js';
	import { Icon } from '@/components/icon/icon.js';
	import modalHeader from '@/components/modalHeader/index.vue';
	import userSelectModal from '@/components/userSelect/index.vue';
	export default {
		components: { Icon, modalHeader, userSelectModal },
		data() {
			return {
				modelRef: {},
				visible: false,
				data:[],
				checked: false,
				oldUserData: [],
				newUserData: [],
				oldTimeout: null,
				newTimeout: null
			}
		},
		created() {},
		methods: {
			async getData(item,user) {
				this.loading = true;
				try {
					let ret = await getAllUserList({
						usernameOrPhone: item,
					});
					this.loading = false;
					if (ret.code === 200) {
						if (ret.data.length === 0) {
							this.$message.warning('暂无该用户');
						}
						if(user == "old") {
							this.oldUserData= ret.data.map(item => {
								return {
									id: item.id,
									username: item.phone + '-' + item.nickname
								}
							});
						}else{
							this.newUserData= ret.data.map(item => {
								return {
									id: item.id,
									username: item.phone+ '-' + item.nickname
								}
							});
						}
					}
				} catch(e) {
					this.loading = false;
				}
			},
			onSubmit() {
				this.$refs.formRef.validate().then(async () => {
					let postData = this.$deepClone(this.modelRef)
					postData.isAll = this.checked? 1 : 0
					postData = JSON.parse(JSON.stringify(postData));
					this.loading = true;
					try {
						let ret = await updateAssetsUser({...postData});
						this.loading = false;
						if (ret.code === 200) {
							this.$message.success('转移成功');
							this.$emit('refresh');
							this.modelRef = {}
							this.close();
						}
					} catch(e) {
						this.loading = false;
					}
				})
			},
			open() {
				this.visible = true;
			},
			close() {
				this.$refs.formRef.clearValidate();
				this.visible = false;
			},
			oldHandleSearch(value) {
				if (this.oldTimeout) clearTimeout(this.oldTimeout);
				this.oldTimeout = setTimeout(() => {
					this.getData(value,"old")
				}, 1000);
			},
			newHandleSearch(value) {
				if (this.newTimeout) clearTimeout(this.newTimeout);
				this.newTimeout = setTimeout(() => {
					this.getData(value,"new")
				}, 1000);
			},
			handleChange() {}
		}
	}
</script>

<style>
</style>